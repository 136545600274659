import React, { useState, useEffect } from 'react';
import { db } from './firebase';
import { collection, addDoc, getDocs, query, orderBy, doc, updateDoc, arrayUnion, Timestamp, increment } from 'firebase/firestore';
import { FaChevronRight, FaLock, FaUnlock, FaMapMarkerAlt, FaTimes, FaArrowLeft } from 'react-icons/fa';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix for default marker icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second
const ANSWER_COOLDOWN = 5000; // 5 seconds cooldown between answers
const ANSWER_LOADING_TIME = 2000;

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const LocationMarker = ({ position, setPosition }) => {
  const map = useMapEvents({
    click(e) {
      setPosition(e.latlng);
      map.flyTo(e.latlng, map.getZoom());
    },
  });

  return position ? <Marker position={position} /> : null;
};

const App = () => {
  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState('');
  const [expandedQuestion, setExpandedQuestion] = useState(null);
  const [newAnswers, setNewAnswers] = useState({});
  const [questionFeedback, setQuestionFeedback] = useState(false);
  const [answerFeedback, setAnswerFeedback] = useState({});
  const [unlockedQuestions, setUnlockedQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [lastAnswerTime, setLastAnswerTime] = useState(0);
  const [isChangingLocation, setIsChangingLocation] = useState(false);
  const [location, setLocation] = useState({ latitude: 36.0022, longitude: -78.9383 });
  const [mapCenter, setMapCenter] = useState([36.0022, -78.9383]);
  const [mapPosition, setMapPosition] = useState([36.0022, -78.9383]);
  const [hasAnsweredOnce, setHasAnsweredOnce] = useState(false);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [topCategories, setTopCategories] = useState([]);
  const [categoryPath, setCategoryPath] = useState([]);
  //const [allCategories, setAllCategories] = useState({});



  useEffect(() => {
    fetchQuestions();
    getCurrentLocation();
  }, []);

  useEffect(() => {
    if (questions.length > 0) {
      setFilteredQuestions(questions);
      const categories = extractAllCategories(questions);
      //setAllCategories(categories);
      setTopCategories(getTopCategories(categories, 5));
    }
  }, [questions]);


  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const newLocation = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          };
          setLocation(newLocation);
          setMapCenter([newLocation.latitude, newLocation.longitude]);
          setMapPosition([newLocation.latitude, newLocation.longitude]);
        },
        () => {
          // Silently use the default location
          console.log("Using default location");
        },
        { 
          enableHighAccuracy: true, 
          timeout: 5000, 
          maximumAge: 0 
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser. Using default location.");
    }
  };

  const handleLocationChange = () => {
    if (mapPosition) {
      setLocation({
        latitude: mapPosition.lat,
        longitude: mapPosition.lng
      });
    }
    setIsChangingLocation(false);
  };


  const handleCloseMap = () => {
    setIsChangingLocation(false);
    // Reset mapPosition if you don't want to keep the selected position
    // setMapPosition(null);
  };

  const fetchQuestions = async () => {
    const q = query(collection(db, 'questions'), orderBy('timestamp', 'desc'));
    const querySnapshot = await getDocs(q);
    const fetchedQuestions = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      showComments: false,
      commentsLoaded: false,
    }));
    setQuestions(fetchedQuestions);
  };
  const extractAllCategories = (questionSet) => {
    const categoriesCount = {};
    questionSet.forEach(question => {
      if (question.categories) {
        question.categories.forEach(category => {
          categoriesCount[category] = (categoriesCount[category] || 0) + 1;
        });
      }
    });
    return categoriesCount;
  };

  
    
    const getTopCategories = (categories, count) => {
      return Object.entries(categories)
        .sort((a, b) => b[1] - a[1])
        .slice(0, count)
        .map(([category]) => category);
    };

    const handleCategorySelection = (category) => {
      const newFilteredQuestions = filteredQuestions.filter(q => 
        q.categories && q.categories.includes(category)
      );
      setFilteredQuestions(newFilteredQuestions);
      
      const newCategoryPath = [...categoryPath, category];
      setCategoryPath(newCategoryPath);
      
      const newCategories = extractAllCategories(newFilteredQuestions);
      //setAllCategories(newCategories);
      
      const newTopCategories = getTopCategories(newCategories, 5)
        .filter(cat => !newCategoryPath.includes(cat));
      setTopCategories(newTopCategories);
    };

    const handleBackNavigation = () => {
      if (categoryPath.length > 0) {
        const newPath = categoryPath.slice(0, -1);
        setCategoryPath(newPath);
        
        let newFilteredQuestions = questions;
        newPath.forEach(category => {
          newFilteredQuestions = newFilteredQuestions.filter(q => 
            q.categories && q.categories.includes(category)
          );
        });
        
        setFilteredQuestions(newFilteredQuestions);
        const newCategories = extractAllCategories(newFilteredQuestions);
        //setAllCategories(newCategories);
        
        const newTopCategories = getTopCategories(newCategories, 5)
          .filter(cat => !newPath.includes(cat));
        setTopCategories(newTopCategories);
      }
    };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newQuestion.trim() === '') return;
  
    setIsLoading(true);
    setError(null);
  
    try {
      await sleep(ANSWER_LOADING_TIME);
      const { isValid, categories } = await checkQuestionValidityAndGenerateCategories(newQuestion);
      
      if (!isValid) {
        throw new Error('Question is inappropriate or invalid.');
      }
  
      if(!location){
        setLocation({
          latitude: 36.0022,
          longitude: -78.9383
        });
      }
  
      await addDoc(collection(db, 'questions'), {
        question: newQuestion,
        timestamp: Timestamp.now(),
        answers: [],
        timesAnswered: 0,
        timesLoaded: 0,
        location: location,
        categories: categories
      });
  
      setNewQuestion('');
      setQuestionFeedback(true);
      setTimeout(() => setQuestionFeedback(false), 500);
      fetchQuestions();
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleExpand = (id) => {
    setExpandedQuestion(expandedQuestion === id ? null : id);
  };

  const handleAddAnswer = async (questionId) => {
    const answerText = newAnswers[questionId]?.trim();
    if (!answerText ) return;
  
    const now = Date.now();
    if (now - lastAnswerTime < ANSWER_COOLDOWN) {
      setError(`Please wait ${Math.ceil((ANSWER_COOLDOWN - (now - lastAnswerTime)) / 1000)} seconds before submitting another answer.`);
      return;
    }
  
    setIsLoading(true);
    setError(null);
  
    try {
      const question = questions.find(q => q.id === questionId).question;
      
      await sleep(ANSWER_LOADING_TIME);
      await checkAnswerRelevance(question, answerText);
  
      const questionRef = doc(db, 'questions', questionId);
      await updateDoc(questionRef, {
        answers: arrayUnion({
          text: answerText,
          timestamp: Timestamp.now(),
          location: location
        }),
        timesAnswered: increment(1)
      });
  
      setNewAnswers(prev => ({ ...prev, [questionId]: '' }));
      setAnswerFeedback(prev => ({ ...prev, [questionId]: true }));
      setTimeout(() => setAnswerFeedback(prev => ({ ...prev, [questionId]: false })), 500);
      
      // Set this after the first answer is submitted
      setHasAnsweredOnce(true);
  
      setLastAnswerTime(Date.now());
      fetchQuestions();
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  




  const checkAnswerRelevance = async (question, answer, retries = 0) => {
    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [
            { role: 'system', content: 'You are a helpful assistant that moderates answers to questions.' },
            { role: 'user', content: `Evaluate the following answer:\n\nQuestion: ${question}\nAnswer: ${answer}\n\nRespond with only "RELEVANT", "SPAM", or "INAPPROPRIATE".` }
          ]
        },
        {
          headers: {
            'Authorization': `Bearer ${OPENAI_API_KEY}`,
            'Content-Type': 'application/json'
          }
        }
      );

      const result = response.data.choices[0].message.content.trim().toUpperCase();
      console.log(result);

      if (result === 'RELEVANT') {
        return true;
      } else if (result === 'IRRELEVANT') {
        throw new Error('Answer seems to be irrelevant to the question.');
      } else if (result === 'SPAM') {
        throw new Error('Answer seems to be spam or promotional content.');
      } else if (result === 'INAPPROPRIATE') {
        throw new Error('Answer seems to be inappropriate.');
      } else {
        throw new Error('Unable to determine answer relevance.');
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 429 && retries < MAX_RETRIES) {
        console.log(`Rate limited. Retrying in ${RETRY_DELAY}ms...`);
        await sleep(RETRY_DELAY);
        return checkAnswerRelevance(question, answer, retries + 1);
      }
      //console.error('Error checking answer relevance:', error);
      throw new Error('Answer is not relevant.');
    }
  };

  const checkQuestionValidityAndGenerateCategories = async (question, retries = 0) => {
    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [
            { role: 'system', content: 'You are a helpful assistant that evaluates questions and generates categories of duke university students.' },
            { role: 'user', content: `Evaluate the following text as a question and generate a very extensive list of broad, general categories for it if valid:

"${question}"

Respond with a JSON object containing two fields: 
1. "isValid" (boolean): true if the question is appropriate and valid, false otherwise.
2. "categories" (array of strings): If valid, provide a very extensive list of categories in a hierarchical order, starting from extremely broad and becoming increasingly specific. If invalid, this should be an empty array.

For example, for a question like "How crowded does the Bryan Center Starbucks get during midterms week?", appropriate categories might be: ["Campus", "Dining", "Dining", "Starbucks", "Midterms", "Study Periods", "Popular Locations", "Time Management", "Coffee", "Brian Center", ...]

Ensure categories are broad enough to encompass similar questions but specific enough to be meaningful.` }
          ]
        },
        {
          headers: {
            'Authorization': `Bearer ${OPENAI_API_KEY}`,
            'Content-Type': 'application/json'
          }
        }
      );
  
      const result = JSON.parse(response.data.choices[0].message.content);
  
      return {
        isValid: result.isValid,
        categories: result.categories
      };
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 429 && retries < MAX_RETRIES) {
        console.log(`Rate limited. Retrying in ${RETRY_DELAY}ms...`);
        await sleep(RETRY_DELAY);
        return checkQuestionValidityAndGenerateCategories(question, retries + 1);
      }
      console.error('Error checking question validity and generating categories:', error);
      throw new Error('Failed to process the question.');
    }
  };

  

  const handleAnswerKeyPress = (e, questionId) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleAddAnswer(questionId);
    }
  };

  const toggleComments = async (id) => {
    if (hasAnsweredOnce && !unlockedQuestions.includes(id)) {
      setUnlockedQuestions(prev => [...prev, id]);
      setQuestions(questions.map(q => 
        q.id === id ? { ...q, showComments: true, commentsLoaded: true } : q
      ));
  
      // Increment the timesLoaded counter when answers are loaded
      const questionRef = doc(db, 'questions', id);
      await updateDoc(questionRef, {
        timesLoaded: increment(1)
      });
    }
  };
  

  const canUnlock = (id) => hasAnsweredOnce && !unlockedQuestions.includes(id);


  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="max-w-3xl mx-auto">
        <style>
          {`
            @keyframes shake {
              0% { transform: translateX(0); }
              25% { transform: translateX(-5px); }
              50% { transform: translateX(5px); }
              75% { transform: translateX(-5px); }
              100% { transform: translateX(0); }
            }
            .shake-animation {
              animation: shake 0.5s ease-in-out;
            }
            .success-color {
              transition: background-color 0.3s ease-in-out;
            }
            .success-color-active {
              background-color: #4ade80;
            }
          `}
        </style>
        <h1 className="text-3xl font-bold mb-8 text-center">Answers Around You</h1>
        
        <div className="mb-4 flex items-center justify-between">
          <div className="flex items-center">
            <FaMapMarkerAlt className="text-red-500 mr-2" />
            <span className="text-sm">
              Current location: {location.latitude.toFixed(4)}, {location.longitude.toFixed(4)}
            </span>
          </div>
          <button
            onClick={() => setIsChangingLocation(true)}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            Change Location
          </button>
        </div>

        {isChangingLocation && (
          <div className="mb-4 relative">
            <div className="h-64 mb-2">
              <MapContainer center={mapCenter} zoom={13} style={{ height: '100%', width: '100%' }}>
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <LocationMarker position={mapPosition} setPosition={setMapPosition} />
              </MapContainer>
            </div>
            <button
              onClick={handleCloseMap}
              className="absolute top-2 right-2 bg-white text-gray-700 p-2 rounded-full hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
            >
              <FaTimes />
            </button>
            <div className="flex space-x-2">
              <button
                onClick={handleLocationChange}
                className="flex-1 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
              >
                Set New Location
              </button>
              <button
                onClick={handleCloseMap}
                className="flex-1 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        
        <form onSubmit={handleSubmit} className="mb-8 relative">
          <input
            type="text"
            value={newQuestion}
            onChange={(e) => setNewQuestion(e.target.value)}
            placeholder={ "Ask a new question..."}
            className={`w-full p-3 rounded-lg shadow-sm border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 success-color ${questionFeedback ? 'shake-animation success-color-active' : ''} `}
          />

          {isLoading && <p className="text-sm text-gray-500 mt-1">Checking question appropriateness...</p>}
          {error && <p className="text-sm text-red-500 mt-1">{error}</p>}
        </form>
        <div className="mb-4">
          {categoryPath.length > 0 && (
            <button 
              onClick={handleBackNavigation}
              className="mb-2 px-3 py-1 bg-gray-200 text-gray-700 rounded flex items-center"
            >
              <FaArrowLeft className="mr-2" /> Back
            </button>
          )}
          <div className="mb-2">
            {categoryPath.map((cat, index) => (
              <span key={index} className="text-sm text-gray-600">
                {index > 0 && " > "}
                {cat}
              </span>
            ))}
          </div>
          <div className="flex flex-wrap gap-2">
            {topCategories.map(category => (
              <button
                key={category}
                onClick={() => handleCategorySelection(category)}
                className="px-3 py-1 bg-gray-200 text-black rounded hover:bg-blue-600"
              >
                {category}
              </button>
            ))}
          </div>
        </div>


        <div className="space-y-4">
          {filteredQuestions.map((q) => (
            <div key={q.id} className="bg-white rounded-lg shadow-md overflow-hidden">
              <div className="p-4 flex items-center justify-between">
                <div>
                  <p className="text-lg font-medium">{q.question}</p>
                  
                </div>
                <button
                  onClick={() => toggleExpand(q.id)}
                  className="text-blue-500 hover:text-blue-700"
                >
                  <FaChevronRight className={`transform transition-transform ${expandedQuestion === q.id ? 'rotate-90' : ''}`} />
                </button>
              </div>
              {expandedQuestion === q.id && (
                <div className="p-4 bg-gray-50 border-t border-gray-200">
                  <div className="text-sm text-gray-600 mb-2 flex justify-between">
                  <span>Answers: {q.answers?.length || 0}</span>
                  <span>Asked on: {q.timestamp?.toDate().toLocaleDateString()}</span>
                  </div>

                  <div className="mb-4">
                    <input
                      type="text"
                      value={newAnswers[q.id] || ''}
                      onChange={(e) => setNewAnswers(prev => ({ ...prev, [q.id]: e.target.value }))}
                      onKeyPress={(e) => handleAnswerKeyPress(e, q.id)}
                      placeholder="Add an answer..."
                      className={`w-full p-2 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 success-color ${answerFeedback[q.id] ? 'shake-animation success-color-active' : ''}`}
                      disabled={isLoading}
                    />
                    {error && <p className="text-sm text-red-500 mt-1">{error}</p>}
                    {isLoading && <p className="text-sm text-blue-500 mt-1">Processing your answer... This may take a few seconds.</p>}
                  </div>
                  {!q.commentsLoaded && (
                    <button
                      onClick={() => toggleComments(q.id)}
                      className={`w-full px-4 py-2 ${canUnlock(q.id) ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} rounded hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 ${canUnlock(q.id) ? 'focus:ring-blue-500' : 'focus:ring-gray-500'} flex items-center justify-center`}
                      disabled={!canUnlock(q.id)}
                    >
                      {canUnlock(q.id) ? <FaUnlock className="mr-2" /> : <FaLock className="mr-2" />}
                      {canUnlock(q.id) ? "View Answers" : "Answer any question to unlock"}
                    </button>
                  )}
                  {q.showComments && (
                    <div className="mt-4 space-y-2">
                      {q.answers && q.answers.length > 0 ? (
                        q.answers.map((answer, index) => (
                          <div key={index} className="bg-white p-3 rounded shadow">
                            <p>{answer.text}</p>
                            <p className="text-xs text-gray-500 mt-1">
                              {answer.timestamp?.toDate().toLocaleDateString()}
                            </p>

                          </div>
                        ))
                      ) : (
                        <p className="text-gray-500">No answers yet.</p>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default App;
